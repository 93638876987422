import { DEFAULT_LANGUAGE } from './feature-flags';
/**
 * ************************************************
 * Time & Language Property
 * ************************************************
 */
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone.js';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/id';
import 'dayjs/locale/en';

dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.setDefault('Asia/Jakarta');

export const PASSWORD_LINK_GENERATOR = 'happywedding';

/**
 * ************************************************
 * Flag to to set Bride to BOY first
 * ************************************************
 */
export const IS_BOY_FIRST = true;

/**
 * ************************************************
 * Bride & Groom Info
 * ************************************************
 */
export const GIRL_NAME = 'Wenni Indrasari, Gan';
export const GIRL_NAME_SHORT = 'Wenni';
export const GIRL_FATHER_NAME = `Untung Kristanto (†)`;
export const GIRL_MOTHER_NAME = `Mey Kui`;
export const GIRL_PARENT_NAME =
  DEFAULT_LANGUAGE === 'en'
    ? `<b>The daughter of</b><br /> Mr. ${GIRL_FATHER_NAME} <br /> and Mrs. ${GIRL_MOTHER_NAME}`
    : `<b>Putri dari</b> <br />Bapak ${GIRL_FATHER_NAME} <br /> dan Ibu ${GIRL_MOTHER_NAME}`;

// -> boy section
export const BOY_NAME = 'Hendrawan Susanto, Thio';
export const BOY_NAME_SHORT = 'Hendra';
export const BOY_FATHER_NAME = `Thio Pik Tjoe (†)`;
export const BOY_MOTHER_NAME = `Lily Hardjosoesanto / Lie Giok Lie (†)`;
export const BOY_PARENT_NAME =
  DEFAULT_LANGUAGE === 'en'
    ? `<b>The son of</b><br /> Mr. ${BOY_FATHER_NAME} <br /> and Mrs. ${BOY_MOTHER_NAME}`
    : `<b>Putra dari</b><br /> Bapak ${BOY_FATHER_NAME}<br /> dan Ibu ${BOY_MOTHER_NAME}`;

// -> bride section
export const BRIDE_HASHTAG = `#loveinWHENderland`;
export const THE_BRIDE = IS_BOY_FIRST
  ? `${BOY_NAME_SHORT} & ${GIRL_NAME_SHORT}`
  : `${GIRL_NAME_SHORT} & ${BOY_NAME_SHORT}`;

/**
 * ************************************************
 * Instagram Profile Account
 * @important please put instagram id without `@`
 * ************************************************
 */
export const IG_BOY = 'hendrawan_susanto';
export const IG_GIRL = 'weenniiii';
export const IG_FILTER_URL = `https://www.instagram.com/ar/2625709477587765/`;

/**
 * ************************************************
 * SEO Requirement
 * @important - Don't forget to update SEO IMAGE
 * ************************************************
 */
export const SEO_IMAGE = `https://ik.imagekit.io/farahluthfioktarina/hendrawenni/seo.jpeg?updatedAt=1709353233945`;
export const SEO_URL = 'https://invitato.net/hendrawenni';
export const SEO_TITLE = `The Wedding of ${THE_BRIDE} by Invitato`;
export const SEO_DESCRIPTION =
  DEFAULT_LANGUAGE === 'en'
    ? `Together with joyful hearts, we re pleased to announce the beginning of this new chapter of our lives together. Please click the Website Invitation link on this message for more information about our wedding details.`
    : `Menjadi sebuah kebahagiaan bagi kami untuk mengumumkan awal dari babak baru kehidupan kami bersama. Silakan klik tautan situs Undangan Website di bawah untuk informasi lebih lanjut:`;

/**
 * ************************************************
 * Time requirement for Counting Down, and
 * Remind me generator
 * @important - please convert time to Epoch time by
 * using this link https://www.epochconverter.com/
 * ************************************************
 */
export const EPOCH_START_EVENT = 1714881600;
export const EPOCH_RESEPSI_START = 1714906800;
export const EPOCH_END_AKAD = 1714888800;
export const EPOCH_END_EVENT = 1714914000;

/**
 * ************************************************
 * DATE REQUIREMENT
 * @important - Format date YYYY-MM-DD
 * ************************************************
 */
export const DATE_AKAD = dayjs.tz('2024-05-05');
export const DATE_RESEPSI = dayjs.tz('2024-05-05');

export const WEDDING_AKAD_TIME = '11.00 WIB';
export const WEDDING_AKAD_LOC_NAME = 'GBT Alfa Omega Puri Anjasmoro';
export const WEDDING_AKAD_LOC_ROAD = `Jalan Puri Anjasmoro No. 10, Blok - J1, Tawangsari, Kec. Semarang Barat, Kota Semarang`;
export const WEDDING_AKAD_DRESSCODE = ``;
export const WEDDING_AKAD_FULLDATE = dayjs(DATE_AKAD)
  .locale(DEFAULT_LANGUAGE)
  .format('dddd, DD MMMM YYYY');
// in case you need custom format for full date, just uncomment below section
// export const WEDDING_AKAD_FULLDATE = `Saturday, June 18<sup>th</sup>, 2022`;

export const WEDDING_RESEPSI_TIME = '18.00 WIB';
export const WEDDING_RESEPSI_LOC_NAME = 'Palace Fine Cuisine & Ballroom Lt.3';
export const WEDDING_RESEPSI_LOC_ROAD = `Jalan MH. Thamrin No. 5, Sekayu, Kec. Semarang Tengah, Kota Semarang`;
export const WEDDING_RESEPSI_DRESSCODE = ``;
export const WEDDING_RESEPSI_FULLDATE = dayjs(DATE_RESEPSI)
  .locale(DEFAULT_LANGUAGE)
  .format('dddd, DD MMMM YYYY');
// in case you need custom format for full date, just uncomment below section
// export const WEDDING_RESEPSI_FULLDATE = `Saturday, June 18<sup>th</sup>, 2022`;
export const WEDDING_RESEPSI_DATE = dayjs(DATE_RESEPSI)
  .locale(DEFAULT_LANGUAGE)
  .format('DD • MMMM • YYYY');
export const IS_SAME_LOCATION =
  WEDDING_AKAD_LOC_NAME === WEDDING_RESEPSI_LOC_NAME &&
  WEDDING_AKAD_LOC_ROAD === WEDDING_RESEPSI_LOC_ROAD;

/**
 * ********************************************************
 * Link Generator V2
 * @important - this info will be applied at link generator
 * ********************************************************
 */
export const HOSTNAME = 'https://hendrawenni.com/';
export const BROADCAST_WEDDING_LOCATION = WEDDING_RESEPSI_LOC_NAME;
export const BROADCAST_WEDDING_DAY = {
  id: dayjs(DATE_RESEPSI).locale('id').format('dddd, DD MMMM YYYY'),
  en: dayjs(DATE_RESEPSI).locale('en').format('dddd, DD MMMM YYYY'),
};

/**
 * ************************************************
 * Maps Location
 * ************************************************
 */
export const GOOGLE_MAPS_AKAD = `https://maps.app.goo.gl/GhcVWmcW3x2zDN6n6`;
export const GOOGLE_MAPS_RESEPSI = `https://maps.app.goo.gl/6YoCVS5PxteBysQ3A`;
export const GOOGLE_MAPS_ADDRESS_AKAD = WEDDING_AKAD_LOC_NAME;
export const GOOGLE_MAPS_ADDRESS = WEDDING_RESEPSI_LOC_NAME;

/**
 * ************************************************
 * Backsound Copyright
 * ************************************************
 */
export const SOUND_BY = `Pentatonix - Can You Feel the Love Tonight`;
export const SOUND_URL = 'https://www.youtube.com/watch?v=cT1Kzk7akjQ';

/**
 * ************************************************
 *  Youtube Live requirement
 * ************************************************
 */
export const YOUTUBE_LINK = 'https://youtu.be/gp9uomY7k-Q';
export const YOUTUBE_EMBED = 'https://www.youtube.com/embed/gp9uomY7k-Q';
export const PREWEDDING_LINK = 'https://youtu.be/gp9uomY7k-Q';
export const PREWEDDING_EMBED = 'https://www.youtube.com/embed/gp9uomY7k-Q';

/**
 * ************************************************
 *  Invitato Link Requirement
 * ************************************************
 */
export const INVITATO_URL = 'https://invitato.id';
